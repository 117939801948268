import React, { useContext, useEffect } from 'react';
import Loadable from '@loadable/component';
import { Link } from "gatsby"
import AppContext from "@src/context"
// Utility imports
import Image from '@components/utility/Image';
import Seo from '@components/utility/SEO';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import SparklesDosing from '@components/content/svgs/SparklesDosing';
// Content imports
import BottomCta from '@components/content/BottomCta';

import './styles.scss';

// Content imports
const DosingCalc = Loadable(() => import('@components/content/dosing-calc/DosingCalc'));
const WhizzbangLine = Loadable(() => import('@components/content/WhizzbangLine'));
const InViewport = Loadable(() => import('@components/utility/InViewport'));

const Dosing = ({data}) => {
	const ctx = useContext(AppContext);

	const image_whizzbangBent = getImage(data.whizzbangBent);
	const image_dosing = getImage(data.dosing);
	const image_patient = getImage(data.patient);
	const image_patientM = getImage(data.patientM);
	const image_pills = getImage(data.pills);
	const image_pillBottle = getImage(data.pillBottle);

	useEffect(() => {
		ctx.setPageClass('page--dosing');
	}, []);

	return (<>
		<section className="relative">
			<div className="container relative container--inner">
				<h1 className="pb-0 heading heading-hero-blue min-w-[320px] w-[90%] md:w-11/12 xl:w-full">Convenient <br className="hidden xl:block" />once&#8209;daily dosing <br className="hidden xl:block" />to help him stay<br className="hidden 3xl:inline" /> engaged in the now</h1>
				<p className="orange-heading my-5 max-w-[41.875rem]">EMFLAZA is available in tablet and liquid forms and can be taken with or without food. Recommended dosing is approximately 0.9&#160;mg/kg/day for both formulations.</p>
				<WhizzbangLine image={image_whizzbangBent} addedClass="dosing-hero-whizzbang hidden md:block" />
			</div>
			<WhizzbangLine image={image_whizzbangBent} addedClass="dosing-hero-whizzbang md:hidden" />
		</section>
		<section className="relative md:pb-12">
			<div className="container container--inner">
				<div className="max-w-[578px]">
					<p>Below you will find helpful information about how to take EMFLAZA, dosing considerations, and potential drug interactions.</p>
					<h2 className="my-5 text-xl font-black uppercase text-emflaza-blue-200">Using the Dosing Assistant</h2>
					<p>It's important to be mindful of changes in weight as they may impact your son's dosing. You can calculate dosing according to weight using the simple tool below.</p>
					<p className="mt-2 mb-9 md:mb-12">Note: Speak to your son's healthcare provider before making any dosing changes.</p>
				</div>
				<DosingCalc />
			</div>
			<div className='dosing'>
				<div className='dosing-content container container--inner'>
					<div className='dosing-content--copy'>
						<h2 className='orange-heading uppercase'>Get the weight-based dosing guide</h2>
						<p>Annual dose adjustments associated with yearly weight changes may not be enough. Use this guide and regularly monitor your son's weight. Speak to your healthcare provider if you have questions.</p>
						<div className='space-y-3'>
							<a className="kf-anchorlink link-btn link-btn--blue" href="/dosing-guide.pdf" target="_blank" rel="noopener noreferrer">Download in English</a>
							<a className="kf-anchorlink link-btn link-btn--blue" href="/guia-de-administracion.pdf" target="_blank" rel="noopener noreferrer">Download in Spanish</a>
						</div>
						<Link to="/es" lang="es" className="text-emflaza-orange-100 underline inline-block hover:no-underline focus:no-underline">Obtenga más información sobre EMFLAZA en español.</Link>
					</div>
					<div className='dosing-content--image'>
						<Image imageData={image_dosing} alt="" />
					</div>
				</div>
				<div className='relative md:w-[64%] md:ml-auto'>
					<SparklesDosing />
				</div>
			</div>
		</section>
		<section className="relative bg-gradient-orange-left pt-20 desktop:pt-0 pb-24 lg:mt-44 overflow-x-hidden md:overflow-visible">
			<div className="boy-image-m top-0 lg:hidden w-[693px] sm:w-full md:w-screen">
				<div className='relative'>
					<Image imageData={image_patientM} alt="Bedtiming like besties" />
					<span className="absolute top-[45%] left-[30%] not-actual-patient">Actual EMFLAZA patients.</span>
				</div>
			</div>
			<div className="container relative lg:pt-0 container--inner">
				<div className="bg-boy">
					<InViewport delay={500} disable={ctx.mobile} addedClass='inViewport--image'>
						<div className="boy-image">
							<Image imageData={image_patient} alt="Bedtiming like besties"/>
							<span className="absolute top-[70%] left-[40%] not-actual-patient">Actual EMFLAZA patients.</span>
						</div>
					</InViewport>
				</div>
				<div className="dosing-description md:flex md:justify-between">
					<div className="flex flex-col md:block md:flex-1 md:mr-12 md:max-w-[426px] desktop:mr-8">
						<h2 className="mb-5 text-3xl font-black uppercase md:mb-9 md:text-4xl">
							Tablets are <br className="hidden xl:block" />available in 4&nbsp;different <br className="hidden xl:block" />strengths <br className="md:hidden" />(6&nbsp;<span className="lowercase">mg</span>, 18&nbsp;<span className="lowercase">mg</span>, 30&nbsp;<span className="lowercase">mg</span>, 36&nbsp;<span className="lowercase">mg</span>)
						</h2>
						<ul className="order-3 mt-5 bulleted-list bulleted-list--blue md:mt-0 md:mb-12">
							<li className="mb-4">EMFLAZA can be taken with or without food</li>
							<li className="mb-4">Tablets may be given whole or crushed and can be taken immediately after mixing with applesauce</li>
							<li>You can combine multiple tablets to add up to the prescribed dosing, rounding up to the nearest dose amount</li>
						</ul>
						<div className="w-[216px] order-2 my-0 mx-auto md:w-full md:max-w-[390px]">
							<Image imageData={image_pills} alt="" />
						</div>
					</div>
					<div className="flex flex-col md:relative md:block md:flex-1 md:max-w-[400px] desktop:pl-8">
						<h2 className="order-2 my-5 text-3xl font-black uppercase md:mb-9 md:mt-0 md:text-4xl">
							Also available in&nbsp;liquid&nbsp;form
						</h2>
						<ul className="order-3 bulleted-list bulleted-list--blue">
							<li className="mb-3">Round up to the nearest tenth of a milliliter (mL)</li>
							<li className="mb-3">13&nbsp;mL in a 30&#8209;mL bottle with two 1&#8209;mL oral dispensers included</li>
							<li className="mb-3">Mix EMFLAZA well with 3&#8209;4&nbsp;oz of juice or milk and give immediately</li>
							<li className="mb-3">Store at room temperature</li>
							<li className="mb-3">Replace cap tightly on bottle and clean oral dispenser after each use</li>
							<li className="mb-3">Discard any unused EMFLAZA after 1&nbsp;month of first opening the bottle</li>
							<li>Do not mix EMFLAZA with grapefruit juice; EMFLAZA should not be taken with grapefruit or grapefruit juice</li>
						</ul>
						<div className="order-1 mx-auto mt-4 mb-0 md:absolute md:bottom-full w-[98px] md:mt-0 md:left-1/2 md:-translate-x-1/2 md:translate-y-[-2.25rem]">
							<Image imageData={image_pillBottle} alt="" />
						</div>
					</div>
				</div>
			</div>

			<div className="container mt-6 container--inner md:mt-28">
				<h2 className="mb-5 text-3xl font-black uppercase md:text-4xl">Important Dosing Considerations</h2>
				<p>Your child should not stop taking EMFLAZA abruptly or without checking with their healthcare provider. You may need to gradually reduce the dose rather than discontinue treatment altogether.</p>
				<ul className="my-5 bulleted-list">
					<li className="mb-5 flex">The dosage of EMFLAZA must be decreased gradually if your child has taken it for more than a few days</li>
					<li className="flex">Weight gain should be assessed to determine whether the dose is appropriate</li>
				</ul>
				<p>You may need to adjust the dose as your son grows. Track his bodyweight to help determine the appropriate&nbsp;dosage.</p>
			</div>

			<div className="container container--inner mt-14 md:mt-36">
				<BottomCta
					ctaUrl="/understanding-corticosteroids#understanding-switch"
					image={data}
					hasAnchor
				>
					Considering a switch
				</BottomCta>
			</div>
		</section>
	</>)
}

export default Dosing;


export const pageQuery = graphql`
  query {
	whizzbangBent: file(relativePath: {eq: "icons/whizzbang-line-2-bent.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	dosing: file(relativePath: {eq: "pages/dosing/dosing-guide.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	patient: file(relativePath: {eq: "pages/dosing/dosing-patient-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	patientM: file(relativePath: {eq: "pages/dosing/dosing-patient-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	pills: file(relativePath: {eq: "pages/dosing/pills.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	pillBottle: file(relativePath: {eq: "pages/dosing/pill-bottle.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImage: file(relativePath: {eq: "pages/dosing/dosing-bottom-cta-sparkle-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImageMobile: file(relativePath: {eq: "pages/dosing/dosing-bottom-cta-sparkle-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	iconImage: file(relativePath: {eq: "pages/dosing/switch-bottom-cta.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImage: file(relativePath: {eq: "pages/dosing/dosing-bottom-cta-sparkle-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImageMobile: file(relativePath: {eq: "pages/dosing/dosing-bottom-cta-sparkle-2-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
    return (
		<Seo
			title='Dosing & How to Take EMFLAZA® (deflazacort)'
			description='EMFLAZA® (deflazacort) is available in tablet and liquid forms. See daily recommended dose and dosage forms. View full Prescribing Information & Important Safety Information.' />
    );
};
