import React from 'react';
import './styles.scss';

const SparklesBottomCta = () => {
	return (
		<div className="sparkles sparkles-dosing">
			<img src="/images/icons/sparkles/plus-orange.svg" alt="" className="animated-icon orange-1" />
			<img src="/images/icons/sparkles/circle-blue.svg" className="animated-icon blue-1" alt="" />
			<img src="/images/icons/sparkles/circle-white.svg" className="animated-icon white-1" alt="" />
			<img src="/images/icons/sparkles/plus-orange.svg" alt="" className="animated-icon orange-2" />
			<img src="/images/icons/sparkles/circle-white-small.svg" className="animated-icon white-2" alt="" />
			<img src="/images/icons/sparkles/circle-blue.svg" className="animated-icon blue-2" alt="" />
			<img src="/images/icons/sparkles/circle-orange.svg" className="animated-icon orange-3" alt="" />
			<img src="/images/icons/sparkles/circle-dark-blue.svg" className="animated-icon dark-blue" alt="" />
			<img src="/images/icons/sparkles/circle-white-small-2.svg" className="animated-icon white-3" alt="" />
			<img src="/images/icons/sparkles/circle-white-big-2.svg" className="animated-icon white-4" alt="" />
			<img src="/images/icons/sparkles/circle-orange-2.svg" className="animated-icon orange-4" alt="" />
			<img src="/images/icons/sparkles/circle-white.svg" className="animated-icon white-5" alt="" />
		</div>
	);
};

export default SparklesBottomCta;
